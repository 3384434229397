.form-wrapper-contact {
  max-width: 900px;
  margin: 1em auto;
  
  padding: 0 0.5em;
  padding-bottom: 3em;
  font-size: 1.1em;
  form {
    // border: 2px solid black;
    border-radius: 1em;
    
    textarea, input {
      flex: 1;
      background-color: beige;
    }
    label {
      display: flex;
      justify-content: space-between;
    }
    p {
    
      margin: 1em 0;
    }
    h2 {
      
      font-size: 3em;
      margin: 1em 0;
    }
    .label-text {
      min-width: 4em;
      display: inline-block;
    }
    padding: 1em;
    color: inherit;

    p {
      // font-family: Helvetica;
    }
    button {
      background: #ff2802;
      color: white;
      width: 5em;
    }
  }
}
