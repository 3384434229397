@import url("https://use.typekit.net/xrs1mvz.css");
$red: #c20006;
$black: #1c191a;
$borderWidth: 4px;
$paddingY: 0.35em;
// $sigFont: "proxima nova", "proxima-nova", "Avenir Next", "Helvetica Neue", Helvetica, sans-serif;
$sigFont: alternate-gothic-no-3-d, sans-serif;


.hiring {
  // text-align: center;
  margin-top: 0em;
  a {
    font-size: 0.8em;
    color: white !important;
  }
}
.ordering-menu {
  // background: #f7f7fc;
  margin-top: 0;
}

.site-content {
  min-height: 100vh;
}

.jojos, .kokorollburbank {
  // .footer {
  //   display: none !important;
  // }
  // multi-location-home-page
  font-family: $sigFont;
  font-size: 1.15em;
  color: $black;
  a.product-link, a.product-link:visited, a.product-link:hover {
    display: flex;
    background: $red;
    color: white;
    border: none;
  }
  .menu-category-list-view {
    .photos.grid {

      justify-content: space-between;
    }
    .menu-item {
      // background: none;
      // align-items: center;
      .photo-container {
        width: 300px;
      }
      .food-name {
        margin: 0;
        text-align: left;
        font-weight: 600;
        line-height: 1.5em;

      }
      @media (min-width: 550px) {
        width: 49%
      }

    }
    .content {
      flex: auto;
    }
  }
   .home-page-hero {
    background: url(https://afag.imgix.net/jojos-mediterranean-grill/chicken-kabob-1.jpg?w=2400&auto=format) no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 10em 0;
  }
  .home-page-nav {

    text-align: center;
    a.home-page-nav-link {
      padding: 0.2em 0.3em;
      font-family: $sigFont;
      text-transform: uppercase;
      font-weight: 600;
      color: inherit;
      text-decoration: none;
    }
  }
  .toggle {
    background: $red;
  }

  img.nav-bar-logo {
    display: block;
  }
  .cart.minimized {
    background-color: #f7f7fc;
    // padding-bottom: 0.35em;
    border: none;
    border-bottom: 2px solid rgba(255,255,255,0.8);

  }
  .home-grid {
    // display: flex;
    // flex-wrap: wrap;
  }
  .grid-item {
    // flex: 1;
    width: 100%;
    box-sizing: border-box;
    &.full-width {
      width: 100%;
    }
  }
  .location-header {
    font-size: 1.5em;
    padding: 1em 0.2em;
    font-weight: 900;
    // text-transform: uppercase;
    // background: $black;
    // color: white;
    // font-size: 1.5em;
    // padding: 1em 0.2em;
    // margin-bottom: -0.8em;
    // font-weight: 900;
    // text-align: center;
  }

  @media screen and (max-width: 660px) {
    .grid-item {
      width: 100%;
    }
  }
  .landing-page {
    background: url("https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,w_1200/v1502921542/IMG_0743_aaqqjo.jpg");
    background-size: cover;
    background-position: center 100%;
  }
  .landing-page {
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;

    .with-background {
      background: rgba(0,0,0,0.5);
      padding: 0.4em 0.4em;
    }
    strong {
      // color: $red;
      text-decoration: underline;
    }
  }
  .top-banner {

  }
  .location-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // align-items: center;
    justify-content: center;
    max-width: 1300px;
    margin: 0 auto;

  }
  .location {
    padding: 1.5em 0em;
    max-width: 400px;
    display: block;
    .content {
      // display: inline-block;
      padding: 0.5em;
      background: rgba(255,255,255,0.9);
      border-radius: 0.3em;
    }
  }
  .info.header {
    font-size: 0.7em;
    color: black;
    width: 100%;

    a.order-button {
      margin-top: 0.5em;
      color: white;
      text-decoration: none;
      font-size: 0.8em;
    }
  }

  .contact-page {
    // background: #ed1e25;
    .contact-page-content {
      background-color: rgba(255,174,0,1);
      background: linear-gradient(0deg, #ff1919 0%, rgba(255,174,0,1) 50%);
      box-shadow: -1px 1px 5px;
      padding: 2em 2em;
      border-radius: 0.5em;
      // transform: rotate(-2deg);
      max-width: 720px;
      line-height: 1.8em;
      margin: 2em auto;
      a {
        color: white;
      }
    }
    h2 {
      line-height: 2.2em;
      text-align: center;
      font-size: 2em;
    }
  }
  .top-banner.location {
    color: $black;

  }
  .order-from-altadena {
    text-align: center;
    background: $black;
  }
  .order-location {
    text-align: center;
    background: $black;
    p {
      font-weight: 400;
      font-size: 0.8em;
    }
    .notes {
      // font-size: 0.8em;
    }
  }
  a.order-location-link {
    color: white;
    text-decoration: none;
  }
  .loc-menu-link {
    margin-top: 0.5em;
    a.menu-location-link {
      color: $red;
    }
  }
  .order-button {
    // font-size: 0.8em;
    font-weight: 700;
    display: inline-block;
    background: $red;
    padding: 0.3em 0.5em;
    border-radius: 0.1em;
    box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
  }
  .choose-location {
    min-height: 100px;
    .text {
      color: $red;
    }
  }
  .hero-logo {
    margin: 1em auto;
    max-width: 500px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .landing-page .text {
    color: white;
    // background: rgba(0,0,0,0.5);
    font-weight: 700;
    font-size: 1.5em;
    padding: 0.5em 0.8em;
    img {
      width: 90%;
      max-width: 700px;
      display: block;
      margin: 0 auto;
    }
  }

  a.checkout-button.button {
    background: $red;
    font-family: $sigFont;
    font-weight: 600;
    box-shadow: none;
    border: 2px solid rgb(0, 0, 0);

  }

  /* .category__header {
  font-family: cursive;

} */
  .category-name .text {
    font-family: $sigFont;
    font-weight: 900;
    font-size: 1.8em;
    color: $red;
    background: none;
    position: relative;
    text-transform: uppercase;
  }
  .category-name .text::before {
    content: " ";
    display: inline-block;

    // background: url(https://afag.imgix.net/the-kluckin-chicken/eye.png?w=100&auto=format) no-repeat;
    background-size: contain;
    // width: 28px;
    // height: 32px;
    // margin-right: -0.1em;
    width: 31px;
    height: 35px;
    // margin-right: -0.1em;
    position: absolute;
    left: -5px;
    top: 0.36em;
    // top: 15px;
  }

    .top-banner {
      // height: 30vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      color: white;
      font-weight: 700;
      font-size: 1.5em;
      // text-shadow: 1px 1px 0 $red;
      .fine-print {
        font-size: 0.6em;
        margin: 1em auto;
        max-width: 450px;
        line-height: 1.3em
      }
    }

    .pages-nav {
      text-transform: uppercase;
      font-weight: 700;
      font-family: $sigFont;
      background: #111;
      display: flex;
      flex-wrap: warp;
      align-items: center;

      .react-toggle {
        margin-right: 0.5em;
      }
      ul {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
      }
      @media screen and (max-width: 480px) {
        font-size: 4vw;
      }
    }

    a.page-link {
      text-decoration: none;
      color: white;
      display: block;
      padding: $paddingY 0.3em;
      padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
      // box-sizing: border-box;
      border-bottom: $borderWidth solid #111;
      &:hover, &.active {
        border-bottom: $borderWidth solid $red;
        color: $red;
      }
    }

    .full-menu-link-wrapper {
      padding: 1em 0;
      text-align: center;
      a.button {
        background: $red;
        color: white;
      }
    }

    .menu-item {
      .food-name {
        font-family: $sigFont;
        font-size: 1em;
        font-weight: 500;
        // color: $red;
      }
    }

    .add-to-cart-button button {
      font-family: $sigFont;
      border: 2px solid $red;
      // background: $$black;
      color: $red;
    }

  .location-header {
    background-color: #313131;
    // background: #ed1e25;
    // background: linear-gradient(0deg, #ed1e25 0%, rgba(255,174,0,1) 100%);
    color: white;
    text-align: center;
  }
  .location {

    padding: 2em 1em;
    // display: flex;
    justify-content: center;
    font-size: 1em;
    font-weight: 400;
    .order-location-link {
      font-size: 0.75em;
    }
    .title {
      font-weight: 900;
      font-size: 1.8em;
    }
    .phone {
      margin-top: 0.5em;
    }
    a.phone-link {
      color: $red;

    }
    .address {
      margin-top: 0.5em;
    }
    .hours-of-operation {
      text-align: left;
      margin-top: 0.5em;
      .day {
        // text-align: left;
        display: inline-block;
        min-width: 5em;
      }
    }
  }
}
